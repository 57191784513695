<template>
  <div class="reward-content-setting-table">
    <section>
      <BaseTable
        :data="displayData"
        empty-text="暫無數據"
      >
        <EmptyBlock slot="empty" />
        <BaseElTableColumn prop="name" label="抽獎名稱" align="center" />
        <BaseElTableColumn prop="MemberGameMode" label="抽獎方式" align="center" />
        <BaseElTableColumn prop="enableCustomProbability" label="中獎率計算方式" align="center" />
        <BaseElTableColumn prop="awardWinProbability" label="活動中獎率" align="center">
          <template slot-scope="scope">
            <p class="text-primary-100">{{ rounded(scope.row.awardWinProbability * 100) }}%</p>
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="rewardStatus" label="獎項狀態" align="center">
          <template slot-scope="scope">
            <Tag :type="rewardTagType(scope.row.rewardStatus)">
              {{
                rewardStatusTrans(scope.row.rewardStatus)
              }}
            </Tag>
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="enable" label="活動狀態" align="center">
          <template slot-scope="scope">
            <Tag :type="tagType(scope.row.enable)">
              {{
                statusTrans(scope.row.enable, scope.row.startAt)
              }}
            </Tag>
          </template>
        </BaseElTableColumn>

        <BaseElTableColumn
          prop="startAt"
          label="開始時間"
          width="150"
          align="center"
        >
          <template slot-scope="scope">
            <div>
              {{ dateFormat(scope.row.startAt) }}
            </div>
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn
          prop="endAt"
          label="結束時間"
          width="150"
          align="center"
        >
          <template slot-scope="scope">
            <div>
              {{ dateFormat(scope.row.endAt) }}
            </div>
          </template>
        </BaseElTableColumn>

        <BaseElTableColumn
          prop="name"
          label="操作"
          fixed="right"
          width="150"
          align="center"
        >
          <template slot-scope="scope">
            <TableEditBtnGroup
              editBtn="獎項管理"
              @edit="manageReward(scope.row)"
              @delete="onRowDelete(scope.row)"
            />
          </template>
        </BaseElTableColumn>
      </BaseTable>
    </section>
    <DeleteDialog
      v-if="deleteDialog"
      title="提醒"
      content="刪除後將無法復原，確定要刪除？"
      width="40%"
      @close="deleteDialog = false"
      @delete="deleteMemberGame"
    />
  </div>
</template>

<script>
import { defineComponent, ref, computed } from 'vue'
// APIs
import {
  DeleteMemberGame,
} from '@/api/lottery/memberGame'
import dayjs from '@/lib/dayjs'
import DeleteDialog from '@/components/Dialog/DeleteDialog'
// Utils
import { useRouter } from 'vue-router/composables'
import { useTable } from '@/use/table'
import { get, map, isEmpty, sortBy, first } from 'lodash'
import { rewardStatusTypeConfig } from '@/config/lottery'
import { toFixed } from '@/utils/number'
export default defineComponent({
  name: 'RewardContentSettingTable',
  components: {
    DeleteDialog,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  setup (props, { emit }) {
    const router = useRouter()
    const {
      shopId,
      dateFormat,
    } = useTable()
    const selectRow = ref(null)
    const deleteDialog = ref(false)
    const onRowDelete = (row) => {
      selectRow.value = row
      deleteDialog.value = true
    }
    const statusTrans = (enable, startAt) => {
      const noStart = dayjs(startAt).isAfter(dayjs(new Date()))
      if (noStart) return '未進行'
      if (enable && !noStart) return '進行中'
      if (!enable) return '已結束'
    }
    const tagType = (val) => {
      let type = 'info'
      if (val) type = 'action'
      // if (!val) type = 'info'
      return type
    }
    const rewardTagType = (val) => {
      return get(rewardStatusTypeConfig[val], 'tagType')
    }
    const rewardStatusTrans = (val) => {
      return get(rewardStatusTypeConfig[val], 'label')
    }
    const manageReward = (row) => {
      router.push({
        name: 'RewardManage',
        params: {
          lotteryId: row.LotteryId,
        },
        query: {
          id: row.id,
        },
      })
    }
    const displayData = computed(() => {
      return map(props.tableData, (item) => {
        const id = get(item, 'id')
        const LotteryId = get(item, 'LotteryId')
        const name = get(item, 'name')
        const MemberGameMode = get(item, 'MemberGameMode.name') || '-'
        const enableCustomProbability = get(item.Lottery, 'enableCustomProbability') ? '手動編輯' : '自動分配'
        const awardWinProbability = get(item, 'awardWinProbability')
        const lotteryErrors = get(item, 'lotteryErrors')
        let rewardStatus = 'normal'
        if (!isEmpty(lotteryErrors)) {
          const sortedErrors = sortBy(lotteryErrors, 'priority')
          rewardStatus = get(first(sortedErrors), 'type')
        }
        const enable = get(item, 'enable')
        const startAt = get(item, 'startAt')
        const endAt = get(item, 'endAt')
        return {
          id,
          LotteryId,
          name,
          MemberGameMode,
          enableCustomProbability,
          awardWinProbability,
          rewardStatus,
          enable,
          startAt,
          endAt,
        }
      })
    })
    const deleteMemberGame = async () => {
      const [, err] = await DeleteMemberGame({
        shopId: shopId.value,
        id: selectRow.value.id,
      })
      if (err) return window.$message.error(err)
      deleteDialog.value = false
      // memberGameList.value = res
      emit('refresh')
    }
    const rounded = (value) => {
      return toFixed(value, 2)
    }
    return {
      displayData,
      deleteMemberGame,
      selectRow,
      dateFormat,
      onRowDelete,
      deleteDialog,
      statusTrans,
      tagType,
      manageReward,
      rounded,
      rewardTagType,
      rewardStatusTrans,
    }
  },
})
</script>

<style scoped lang="postcss">
.head-block {
  @apply flex items-center justify-between mb-[24px];
}
.test {
  overflow: auto;
}
</style>
